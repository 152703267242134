/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';

import WebfontLoader from '@dr-kobros/react-webfont-loader';

// webfontloader configuration object. *REQUIRED*.
const config = {
  google: {
    families: [
      'Roboto:300,400,600',
      'Andika New Basic:300,400,600',
      'JetBrains Mono:300,400,600',
      'Lato:300,400,600',
      'Oswald:300,400,600',
      'Raleway:300,400,600',
      'Merriweather:300,400,600',
      'Piazzolla',
      'Open Sans Condensed:300,400,600',
      'Work Sans:300,400,600',
      'Rubik:300,400,600',
      'Russo One:300,400,600',
      'Oxygen:300,400,600',
      'Source Code Pro:300,400,600',
      'PT Sans Narrow:300,400,600',
      'Anton:300,400,600',
      'Crimson Text:300,400,600',
      'Bitter:300,400,600',
      'Lobster:300,400,600',
      'Fjalla One:300,400,600',
      'Dancing Script:300,400,600',
      'Teko:300,400,600',
      'Yanone Kaffeesatz:300,400,600',
      'Bebas Neue:300,400,600',
      'Jura:300,400,600',
      'Noto Sans SC:300,400,600',
      'Comfortaa:300,400,600',
      'Pacifico:300,400,600',
      'Cairo:300,400,600',
      'Architects Daughter:300,400,600',
      'Zilla Slab:300,400,600',
      'Shadows Into Light:300,400,600',
      'EB Garamond:300,400,600',
      'Asap:300,400,600',
      'Archivo Narrow:300,400,600',
      'Play:300,400,600',
      'Caveat:300,400,600',
      'Acme:300,400,600',
      'Bree Serif:300,400,600',
      'Noto Sans HK:300,400,600',
      'Signika:300,400,600',
      'Fira Sans Condensed:300,400,600',
      'Exo:300,400,600',
      'Satisfy:300,400,600',
      'Patrick Hand:300,400,600',
      'Courgette:300,400,600',
      'Barlow Semi Condensed:300,400,600',
      'Alegreya Sans:300,400,600',
      'Ubuntu Condensed:300,400,600',
      'Lobster Two:300,400,600',
      'Kaushan Script:300,400,600',
      'Cardo:300,400,600',
      'Kalam:300,400,600',
      'Orbitron:300,400,600',
      'Great Vibes:300,400,600',
      'Concert One:300,400,600',
      'Philosopher:300,400,600',
      'Allan:300,400,600',
      'Changa:300,400,600',
      'Pragati Narrow:300,400,600',
      'Handlee:300,400,600',
      'Squada One:300,400,600',
      'Economica:300,400,600',
      'Mitr:300,400,600',
      'Yellowtail:300,400,600',
      'Staatliches:300,400,600',
      'Jost:300,400,600',
      'Amaranth:300,400,600',
      'Gochi Hand:300,400,600',
      'Pontano Sans:300,400,600',
      'Marck Script:300,400,600',
      'Tangerine:300,400,600',
      'Julius Sans One:300,400,600',
      'Gentium Book Basic:300,400,600',
      'Nanum Pen Script:300,400,600',
      'Bad Script:300,400,600',
      'Saira Extra Condensed:300,400,600',
      'Miriam Libre:300,400,600',
      'Ubuntu Mono:300,400,600',
    ],
  },
};

// Callback receives the status of the general webfont loading process. *OPTIONAL*
const callback = status => {
  // I could hook the webfont status to for example Redux here.
};

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <WebfontLoader config={config} onStatus={callback}>
          <App />
        </WebfontLoader>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
