import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    min-height: 100%;
    width: 100%;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

font-face {
  font-family: 'roboto';
  src: local('roboto'), url(../../fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

font-face {
  font-family: 'robotobold';
  src: local('robotobold'), url(../../fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
`;
